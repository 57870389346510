import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import {
    Swipe,
    SwipeItem
} from 'vant';
import {
    NavBar
} from 'vant';
import {
    Icon
} from 'vant';
import {
    NoticeBar
} from 'vant';
import {
    Tabbar,
    TabbarItem
} from 'vant';
import http from 'vue-resource'
import {
    PullRefresh
} from 'vant';
import {
    List
} from 'vant';
import {
    Cell
} from 'vant';
import {
    Loading
} from 'vant';
import {
    Image as VanImage
} from 'vant';
import {
    Search
} from 'vant';
import {
    DropdownMenu,
    DropdownItem
} from 'vant';
import {
    IndexBar,
    IndexAnchor
} from 'vant';
import {
    Tab,
    Tabs
} from 'vant';
import {
    Picker
} from 'vant';
import {
    Popup
} from 'vant';

Vue.use(Popup);
Vue.use(Picker);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Search);
Vue.use(VanImage);
Vue.use(Loading);
Vue.use(List);
Vue.use(Cell);
Vue.use(PullRefresh);
Vue.use(http);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NoticeBar);
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VueRouter);


Vue.prototype.siteurl = 'http://cp369.tp.hkqtj.com/';
Vue.http.options.root = 'http://cs369.rn49.com'; //设置默认请求网址 http://cs.369cy.com



const routes = [
    /*
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    */
    {
        path: '/',
        name: 'Index',
        component: () => import( /* webpackChunkName: "about" */ '../views/Index.vue')
    },
    {
        path: '/picture',
        name: 'Picture',
        component: () => import('../views/Picture.vue')
    },
    {
        path: '/picture/show',
        name: 'Pictureshow',
        component: () => import('../views/Pictureshow.vue')
    },
    {
        path: '/kaijiang',
        name: 'Kaijiang',
        component: () => import('../views/Kaijiang.vue')
    },
    {
        path: '/kaijiang/show',
        name: 'Kaijiangshow',
        component: () => import('../views/Kaijiangshow.vue')
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: () => import('../views/Statistics.vue')
    },
    {
        path: '/corpus',
        name: 'Corpus',
        component: () => import('../views/Corpus.vue')
    },
    {
        path: '/corpus/list',
        name: 'Corpuslist',
        component: () => import('../views/Corpuslist.vue')
    },
    {
        path: '/corpus/show',
        name: 'Corpusshow',
        component: () => import('../views/Corpusshow.vue')
    },
    {
        path: '/vote',
        name: 'Vote',
        component: () => import('../views/Vote.vue')
    },
    {
        path: '/helper',
        name: 'Helper',
        component: () => import('../views/Helper.vue')
    },
    // Shengxiao.vue
    {
        path: '/shengxiao',
        name: 'Shengxiao',
        component: () => import('../views/pages/Shengxiao.vue')
    }, {
        path: '/video',
        name: 'Video',
        component: () => import('../views/pages/Video.vue')
    },

]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router