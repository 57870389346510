<template>
  <div id="app">

    <router-view/>

  </div>

</template>

<script>
export default {
  data() {
    return {
      active: 'index',
      routeName:''
    };
  },
  mounted() {
   //this.routeName= this.$route.name;
   //this.$router.path;
   // console.log(this.routeName);
    },
  methods: {
    goToIndex() {
      this.$router.push({ path: "/" });
    },
    goToPicture() {
      this.$router.push({ path: "/picture" });
    },
    goToVote(){
        this.$router.push({ path: "/vote" });
    },
    goToHelper(){
        this.$router.push({ path: "/helper" });
    }
  }
};
</script>



<style>
    @import "./assets/css/chunk-01e837c8.6bc896e7.css";
    @import "./assets/css/chunk-0207bd02.d457a710.css";
    @import "./assets/css/chunk-033aa1d1.5ea29ff9.css";
    @import "./assets/css/chunk-039da50a.e3881afe.css";
    @import "./assets/css/chunk-046f8331.042f0639.css";
    @import "./assets/css/chunk-08a3e5ed.6eb9b8c0.css";
    @import "./assets/css/chunk-08e71716.c38210e9.css";
    @import "./assets/css/chunk-08edd6db.c10a59c1.css";
    @import "./assets/css/chunk-08f2de20.74ca76e0.css";
    @import "./assets/css/chunk-0a251ee1.280b8b1e.css";
    @import "./assets/css/chunk-0b6eb44c.eadd2b3d.css";
    @import "./assets/css/chunk-0ca29641.b7e099b5.css";
    @import "./assets/css/chunk-0cc084e1.cadf634d.css";
    @import "./assets/css/chunk-0e86ffde.b2063cf8.css";
    @import "./assets/css/chunk-0f1ea272.bf545048.css";
    @import "./assets/css/chunk-10c5f1eb.959078a5.css";
    @import "./assets/css/chunk-113b1a4a.5ded0923.css";
    @import "./assets/css/chunk-120b1608.5da11014.css";
    @import "./assets/css/chunk-13302207.96fd168f.css";
    @import "./assets/css/chunk-15905a67.9116f2f1.css";
    @import "./assets/css/chunk-16aa0a28.62d45ec7.css";
    @import "./assets/css/chunk-1733f8d2.37cd6975.css";
    @import "./assets/css/chunk-17628651.77fabb68.css";
    @import "./assets/css/chunk-187d89a7.8320e9f8.css";
    @import "./assets/css/chunk-18df426c.f326183f.css";
    @import "./assets/css/chunk-1bc22362.0e0f186b.css";
    @import "./assets/css/chunk-1c201257.68d0131d.css";
    @import "./assets/css/chunk-1d708ead.e23ec0aa.css";
    @import "./assets/css/chunk-1d7b94da.cc19e961.css";
    @import "./assets/css/chunk-1e926696.450992b0.css";
    @import "./assets/css/chunk-20bf5ff6.b46b4cba.css";
    @import "./assets/css/chunk-220e38b2.a78d7be1.css";
    @import "./assets/css/chunk-236db719.2eba07a2.css";
    @import "./assets/css/chunk-250bcb6a.404f09ee.css";
    @import "./assets/css/chunk-2b1eda8c.8362a775.css";
    @import "./assets/css/chunk-2b86504e.48cfb629.css";
    @import "./assets/css/chunk-2c914c33.55021db3.css";
    @import "./assets/css/chunk-2dafafe6.25a8e05e.css";
    @import "./assets/css/chunk-2fff96c8.00825ea0.css";
    @import "./assets/css/chunk-307f8a40.2b1aed72.css";
    @import "./assets/css/chunk-30de19f3.a453e1fd.css";
    @import "./assets/css/chunk-31cf1aef.d3af9485.css";
    @import "./assets/css/chunk-34996a13.312cb7d1.css";
    @import "./assets/css/chunk-379f28fc.fc3255bb.css";
    @import "./assets/css/chunk-37c8f5f9.bb44d26e.css";
    @import "./assets/css/chunk-388ea488.bdcc704b.css";
    @import "./assets/css/chunk-392fe423.9cafac1f.css";
    @import "./assets/css/chunk-3c176b46.3e367c62.css";
    @import "./assets/css/chunk-3d418da3.314a616d.css";
    @import "./assets/css/chunk-3f6d95b0.d312ddee.css";
    @import "./assets/css/chunk-3f946d26.148520aa.css";
    @import "./assets/css/chunk-3f9f289c.87875f60.css";
    @import "./assets/css/chunk-41a03aaa.426d5cb9.css";
    @import "./assets/css/chunk-422b9f04.c6c8b9af.css";
    @import "./assets/css/chunk-433cb390.0b046f99.css";
    @import "./assets/css/chunk-440ee2cb.65815e95.css";
    @import "./assets/css/chunk-4460b74b.3fd67e50.css";
    @import "./assets/css/chunk-45931b74.d9999687.css";
    @import "./assets/css/chunk-46659f40.a22cbb36.css";
    @import "./assets/css/chunk-47c0d27e.ba01be67.css";
    @import "./assets/css/chunk-486a083d.674acde4.css";
    @import "./assets/css/chunk-496cfcf4.6ae54a23.css";
    @import "./assets/css/chunk-499e2d30.5e90e8a3.css";
    @import "./assets/css/chunk-4a854597.bc3eacfc.css";
    @import "./assets/css/chunk-4b37fbe0.7bfba62b.css";
    @import "./assets/css/chunk-4bc097cb.2a99f6ba.css";
    @import "./assets/css/chunk-4e5d2b75.3b44adae.css";
    @import "./assets/css/chunk-4f5d356f.149b24ab.css";
    @import "./assets/css/chunk-50ff91db.d4461ebf.css";
    @import "./assets/css/chunk-54c2eae1.efbbe226.css";
    @import "./assets/css/chunk-5527fbfe.9e25fd46.css";
    @import "./assets/css/chunk-57db1dc0.6c39cf8d.css";
    @import "./assets/css/chunk-57ecf465.44a274ec.css";
    @import "./assets/css/chunk-5c580e96.ab70246b.css";
    @import "./assets/css/chunk-5d6c0878.1eb60d99.css";
    @import "./assets/css/chunk-5f03bacc.a5d608b9.css";
    @import "./assets/css/chunk-5fec8f5d.424b136b.css";
    @import "./assets/css/chunk-60249ca9.0b86776a.css";
    @import "./assets/css/chunk-60ef1a04.7b8647cd.css";
    @import "./assets/css/chunk-6146b662.1f0d238e.css";
    @import "./assets/css/chunk-61ee7d28.552fc4d8.css";
    @import "./assets/css/chunk-629d0eab.c5a6a59d.css";
    @import "./assets/css/chunk-63fddfe0.025399d3.css";
    @import "./assets/css/chunk-6562cb94.63daaed7.css";
    @import "./assets/css/chunk-65a93ce5.a4dd80d7.css";
    @import "./assets/css/chunk-671dd3be.93cbf522.css";
    @import "./assets/css/chunk-678e0bd3.d5da08ad.css";
    @import "./assets/css/chunk-68dc5bed.e99977fb.css";
    @import "./assets/css/chunk-69232932.ef87ba5b.css";
 
    @import "./assets/css/chunk-6b5512c0.f14c4c9e.css";
    @import "./assets/css/chunk-6da4ef4a.2ca0157c.css";
    @import "./assets/css/chunk-6fd2379e.1c51216b.css";
    @import "./assets/css/chunk-6ffc8f9b.38836ba9.css";
    @import "./assets/css/chunk-70799ee2.96134f34.css";
    @import "./assets/css/chunk-70d8aded.1b972356.css";
    @import "./assets/css/chunk-71663e14.91b21a15.css";
    @import "./assets/css/chunk-71f31dc0.0c6bc046.css";
    @import "./assets/css/chunk-74601896.b67a5021.css";
    @import "./assets/css/chunk-74762053.414f2381.css";
    @import "./assets/css/chunk-75803bc7.ade116dd.css";
    @import "./assets/css/chunk-774cc2da.bdb6b204.css";
    @import "./assets/css/chunk-77682751.7fa6d708.css";
    @import "./assets/css/chunk-7b3b54f6.7d61bd4b.css";
    @import "./assets/css/chunk-7b3c1166.b12f3628.css";
    @import "./assets/css/chunk-7b89e373.d30f902c.css";
    @import "./assets/css/chunk-7c6d70d8.dc7a2048.css";
    @import "./assets/css/chunk-7cd073e3.5f683414.css";
    @import "./assets/css/chunk-7d7eae95.e7883b4f.css";
    @import "./assets/css/chunk-7eb9754b.338d6c7e.css";
    @import "./assets/css/chunk-84e89d5a.3cfa1c69.css";

    @import "./assets/css/chunk-897c34f6.34835f17.css";
    @import "./assets/css/chunk-8fc39390.27e6da64.css";
    @import "./assets/css/chunk-925d0fc6.aa383c22.css";
    @import "./assets/css/chunk-9719fc8e.5d0fc59d.css";
    @import "./assets/css/chunk-a2729296.36eb2fb0.css";
    @import "./assets/css/chunk-afdf03ac.e3e94fc3.css";
    @import "./assets/css/chunk-bba68bf6.3fdca907.css";
    @import "./assets/css/chunk-c708486a.96ccfc50.css";
    @import "./assets/css/chunk-c83296f4.eb4b3287.css";
    @import "./assets/css/chunk-c8eb158c.10035f0c.css";
    @import "./assets/css/chunk-cbf32b4c.4391d6d6.css";
    @import "./assets/css/chunk-d009b060.508d257c.css";
    @import "./assets/css/chunk-d8d68734.f9a557ae.css";
    @import "./assets/css/chunk-ddba49f6.7827197c.css";
    @import "./assets/css/chunk-e6ea8e4a.8787cbce.css";
    @import "./assets/css/chunk-ec730a00.d4674e22.css";
    @import "./assets/css/chunk-ee7c4c2c.615e9d77.css";
    @import "./assets/css/chunk-f1b6c74a.3c7401bf.css";
    @import "./assets/css/chunk-f32460a8.da4cccdf.css";
    @import "./assets/css/chunk-f37a9b5e.835cd210.css";
    @import "./assets/css/chunk-f5dcd840.41fc3dc3.css";
    @import "./assets/css/chunk-fc6b0354.b1858f3c.css";
    @import "./assets/css/app.362336c3.css";
    @import "./assets/css/chunk-vendors.8c4d0a77.css";
    

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}
div#app {
    background-color: #fff;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.home{background-color: #fff;}
.corpusdetail-msg .block-lottery{
    margin-top: 50px;
}
</style>
